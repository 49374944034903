import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/interfaces/IAppState';
import { selectEnterprise, selectUserType } from '../../store/selectors';

import * as FontAwesome from '@fortawesome/free-solid-svg-icons';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { Enterprise } from '../../models/Enterprise';
import { UserType } from '../../models/UserType';
import { downloadFile } from '../../utils/helpers';
import { ConfigService } from '../config';

export interface MenuItemChild {
  label: string;
  url: string;
  onClick?: () => void;
}
export interface MenuItem {
  faIcon: FontAwesome.IconDefinition;
  label: string;
  url?: string;
  onClick?: () => void;
  children?: MenuItemChild[];
}

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  userType!: UserType;
  enterprise!: Enterprise;

  menuItems$: Observable<MenuItem[]> = combineLatest([
    this.store.select(selectUserType).pipe(filter(Boolean)),
    this.store.select(selectEnterprise).pipe(filter(Boolean)),
  ]).pipe(
    tap(([userType, enterprise]) => {
      this.userType = userType;
      this.enterprise = enterprise;
    }),
    map(() => {
      const items = [];
      const hasMenu = this.configService.hasMenu.bind(this.configService);

      if (hasMenu('INICIO')) {
        items.push(this.getMenuInicio());
      }

      let has;
      if (this.userType === 'PJ' && hasMenu('CONFIGURACOES')) {
        items.push(this.getMenuAdmin());
      }

      if (hasMenu('SESSAO_CADASTROS')) {
        items.push(this.getMenuCadastro());
      }

      if (hasMenu('SESSAO_CHAMADOS')) {
        items.push(this.getMenuChamados());
      }

      if (hasMenu('SESSAO_IMPORTACAO')) {
        // Alteração realizada pelo chamado 18736
        if (this.enterprise.tenantNome !== "minsait" && this.userType === "PJ") {
          items.push(this.getMenuDownloadLayouts());
        }
      }

      if (hasMenu('SESSAO_EXTRATO')) {
        if (this.enterprise.tenantNome !== "minsait" && this.userType === "PF") {
          // Alteração realizada pelo chamado 18736
          items.push(this.getMenuExtrato());
        }
      }

      if (hasMenu('SESSAO_PGTOS_E_DEBITOS')) {
        items.push(this.getMenuPagamentosEDebitos());
      }

      if (hasMenu('SESSAO_SEGUNDA_VIA')) {
        items.push(this.getMenuSegundaVia());
      }

      if (hasMenu('SESSAO_DOWNLOADS')) {
        items.push(this.getMenuDownload());
      }

      if (hasMenu('SESSAO_FAQ')) {
        items.push(this.getMenuFaq());
      }

      if (hasMenu('SESSAO_FILA_INTEGRACAO')) {
        if (this.enterprise.tenantNome !== "minsait" && this.userType === "PJ") {
          items.push(this.getFilaIntegracao());
        }
      }

      items.push(this.getMenuSair());

      return items;
    })
  );

  constructor(private store: Store<IAppState>, private configService: ConfigService) {}

  get tenantNome(): string {
    return this.enterprise.tenantNome;
  }

  private baixarGuiaDoConsumidor(): void {
    const url = '/assets/pdfs/GCPS2019.pdf';
    downloadFile(url);
  }

  private getFilaIntegracao(): MenuItem {
    return {
      faIcon: FontAwesome.faFileLines,
      label: this.configService.getLabel('FILA_INTEGRACAO'),
      url: 'fila-integracao',
    };
  }

  private getMenuInicio(): MenuItem {
    return {
      faIcon: FontAwesome.faHome,
      label: this.configService.getLabel('MENU_INICIO'),
      url: 'inicio',
    };
  }

  private getMenuCadastro(): MenuItem {
    const _uniben = this.tenantNome === 'uniben';
    const children: MenuItemChild[] = [
      {
        label: this.configService.getLabel('MENU_LISTA_BENEFICIÁRIOS'),
        url: 'dados-cadastrais/selecionar-beneficiario',
      },
    ];

    if (!_uniben) {
      if (this.configService.hasMenu('CANCELAMENTO_BENEFICIÁRIO')) {
        children.push({
          label: 'Cancelamento RN561',
          url: `dados-cadastrais/cancelamento-imediato${this.userType === 'PJ' ? '/lista' : ''}`,
        });
      }
    }

    return {
      faIcon: FontAwesome.faCog,
      label: this.configService.getLabel('MENU_SESSAO_CADASTROS'),
      children,
    };
  }

  private getMenuChamados(): MenuItem {
    const children: MenuItemChild[] = [
      {
        label: this.configService.getLabel('MENU_LISTA_CHAMADOS'),
        url: 'chamados',
      },
    ];

    if (this.userType === 'PJ') {
      children.push({
        label: 'Relatório de Chamados',
        url: 'chamados/relatorio',
      });
    }

    return {
      faIcon: FontAwesome.faHeadset,
      label: this.configService.getLabel('MENU_SESSAO_CHAMADOS'),
      children,
    };
  }

  private getMenuDownloadLayouts(): MenuItem {
    const children: MenuItemChild[] = [];
    if (this.userType === 'PJ') {
      children.push({
        label: 'Download de Layouts',
        url: 'importacao-beneficiarios/download-layouts',
      });

      children.push({
        label: 'Importar Movimentação',
        url: 'importacao-beneficiarios/importar-movimentacao',
      });

      children.push({
        label: 'Consultar Lotes',
        url: 'importacao-beneficiarios/consulta-lotes',
      });
    }

    return {
      faIcon: FontAwesome.faFileImport,
      label: this.configService.getLabel('MENU_IMPORTACAO_BENEFICIÁRIOS'),
      children,
    };
  }

  private getMenuExtrato(): MenuItem {
    const children: MenuItemChild[] = [];
    const voceclube = this.tenantNome === 'voceclube';

    if (!voceclube) {
      if (this.configService.hasMenu('EXTRATO_COPART')) {
        children.push({
          label: this.configService.getLabel('MENU_EXTRATO_COPART'),
          url: 'extrato/copart',
        });
      }
    }

    if (this.configService.hasMenu('EXTRATO_COBRANCA')) {
      children.push({
        label: this.configService.getLabel('MENU_EXTRATO_COBRANCA'),
        url: 'extrato/cobranca/busca',
      });
    }

    return {
      faIcon: FontAwesome.faFileAlt,
      label: this.configService.getLabel('MENU_SESSAO_EXTRATO'),
      children,
    };
  }

  private getMenuPagamentosEDebitos(): MenuItem {
    const children: MenuItemChild[] = [];

    if (this.configService.hasMenu('DECL_ANUAL_DEB')) {
      children.push({
        label: this.configService.getLabel('MENU_DECL_ANUAL_DEB'),
        url: 'pagamentos-e-debitos/declaracao-anual-de-debito',
      });
    }

    if (this.configService.hasMenu('CARTA_QUITACAO')) {
      children.push({
        label: this.configService.getLabel('MENU_CARTA_QUITACAO'),
        url: 'pagamentos-e-debitos/carta-de-quitacao',
      });
    }

    if (this.userType === 'PF' && this.configService.hasMenu('INFORME_PGTOS')) {
      children.push({
        label: this.configService.getLabel('MENU_INFORME_PGTOS'),
        url: 'pagamentos-e-debitos/informe-de-pagamento',
      });
    }

    return {
      faIcon: FontAwesome.faCreditCard,
      label: this.configService.getLabel('MENU_SESSAO_PGTOS_E_DEBITOS'),
      children,
    };
  }

  private getMenuSegundaVia(): MenuItem {
    const children: MenuItemChild[] = [];

    if (this.configService.hasMenu('SEGUNDA_VIA_BOLETO')) {
      children.push({
        label: this.configService.getLabel('MENU_SEGUNDA_VIA_BOLETO'),
        url: 'segunda-via/boleto',
      });
    }

    return {
      faIcon: FontAwesome.faCopy,
      label: this.configService.getLabel('MENU_SESSAO_SEGUNDA_VIA'),
      children,
    };
  }

  private getMenuDownload(): MenuItem {
    const children: MenuItemChild[] = [];

    if (this.configService.hasMenu('GUIA_DO_CONSUMIDOR')) {
      children.push({
        label: this.configService.getLabel('MENU_GUIA_DO_CONSUMIDOR'),
        url: '',
        onClick: () => this.baixarGuiaDoConsumidor(),
      });
    }
    return {
      faIcon: FontAwesome.faFileDownload,
      label: this.configService.getLabel('MENU_SESSAO_DOWNLOADS'),
      children,
    };
  }

  private getMenuFaq(): MenuItem {
    return {
      faIcon: FontAwesome.faCircleQuestion,
      label: this.configService.getLabel('MENU_SESSAO_FAQ'),
      url: '/perguntas-frequentes',
    };
  }

  private getMenuAdmin(): MenuItem {
    const children: MenuItemChild[] = [
      {
        label: 'Banners',
        url: '/admin/banners',
      },
      {
        label: 'Perguntas Frequentes',
        url: '/admin/perguntas-frequentes',
      },
    ];

    return {
      faIcon: FontAwesome.faUserTie,
      label: 'Configurar (Admin)',
      children,
    };
  }

  private getMenuSair(): MenuItem {
    return {
      faIcon: FontAwesome.faSignOutAlt,
      label: 'Sair',
      url: '/sair',
    };
  }
}
