import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { switchMap } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IncomeReport } from 'src/app/modules/pagamentos-e-debitos/models/IncomeReport';
import { AuthService } from '../../services/auth';
import { IAppState } from '../../store/interfaces/IAppState';
import { selectEnterprise, selectTenantId, selectUser } from '../../store/selectors';
import { EnterpriseModPag } from '../../models/EnterpriseModPag';
import { endpoints } from 'src/environments/endpoints';
import { PaymentReportService } from 'src/app/core/services/payment-report';
import { PaymentReportContract } from 'src/app/core/models/PaymentReportContract';
import { capitalize, capitalizeWords } from 'src/app/core/utils/helpers';

@Component({
  selector: 'app-payment-report-table',
  templateUrl: './payment-report-table.component.html',
  styleUrls: ['./payment-report-table.component.scss'],
})
export class PaymentReportTableComponent {
  @Input() year!: string;

  paymentReport!: IncomeReport;

  cgc!: string;

  administradoraCNPJ!: string;
  administradoraNome!: string;
  administradoraModPag!: string;
  tenantId!: string;
  branch!: string;
  enterprise$ = this.store.select(selectEnterprise);

  user$ = this.store.select(selectUser);
  paymentReportContracts!: PaymentReportContract[];
  
  constructor(private http: HttpClient, 
    private store: Store<IAppState>, 
    private router: Router, 
    private authService: AuthService,
    private paymentReportService: PaymentReportService
  ) {
    this.loadPaymentReport();
  }

  get nomeTitular(): string {
    return this.paymentReport?.families?.[0].beneficiaries?.[0].name ?? '';
  }

  @Input() set incomeReport(_incomeReport: IncomeReport) {
    this.paymentReport = _incomeReport;
  }

  printComponent() {
    window.print();
  }

  private loadPaymentReport(): void {
    const currentNavigation = this.router.getCurrentNavigation();
    const navigationState = currentNavigation?.extras.state;
    this.cgc = currentNavigation?.previousNavigation?.extractedUrl.queryParams.cpf as string;
    this.year = currentNavigation?.previousNavigation?.extractedUrl.queryParams.baseYear as string;
    this.paymentReport = navigationState?.paymentReport as IncomeReport;
    if (!this.cgc && !this.year) {
      this.cgc = navigationState?.cgc as string;
      this.year = navigationState?.baseYear as string;
    }

    this.getModalidadePagamento();

    this.getModalidadePagamento();

    if (!this.paymentReport && !this.authService.isLogged) {
      this.router.navigate(['autenticacao']);
    }
  }

  private getModalidadePagamento() {
    const baseURL = endpoints.enterprise;
    const url = baseURL;
    this.enterprise$.pipe(take(1)).subscribe((empresa) => {
      if (!empresa)
        return;
      this.administradoraCNPJ = empresa.cnpj;
      this.administradoraNome = empresa.clienteNome;
      this.administradoraModPag = '0';
    });

    this.branch = '';
    this.paymentReportService
          .listContracts(this.cgc, this.year)
          .pipe(take(1))
          .subscribe((paymentReportContracts) => {
            this.paymentReportContracts = paymentReportContracts;
            if (paymentReportContracts.length > 0)
              this.branch = paymentReportContracts[0].filial;
          });

    this.store.select(selectTenantId).pipe(take(1)).subscribe((tenantId) => {
      if (this.branch)
        this.tenantId = `${tenantId},${this.branch}`;
      else
        this.tenantId = `${tenantId}`;
    });

    let headers = new HttpHeaders();
    let params = new HttpParams();

    headers = headers.set('tenantid', `${this.tenantId}`); // ,${branch}
    params = params.append('cgc', this.cgc);
    this.http.get<EnterpriseModPag>(url, { params, headers }).subscribe((data) => {
      if (data.modPag === "1") {
        this.administradoraCNPJ = data.cgc;
        this.administradoraNome = capitalizeWords(data.nome);
        this.administradoraModPag = data.modPag;
      }
    });
  }
}
