import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MatStepper } from '@angular/material/stepper';

import { Store } from '@ngrx/store';
import { format, parse } from 'date-fns';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { Beneficiary } from 'src/app/core/models/Beneficiary';
import { BeneficiaryHealthPlan } from 'src/app/core/models/BeneficiaryHealthPlan';
import { CreateBeneficiaryPayload } from 'src/app/core/models/CreateBeneficiaryPayload';
import { ShowDS } from 'src/app/core/models/ShowDS';
import { UserType } from 'src/app/core/models/UserType';
import { BeneficiaryService } from 'src/app/core/services/beneficiary';
import { LoaderService } from 'src/app/core/services/loader';
import { NotificationService } from 'src/app/core/services/notification';
import { IAppState } from 'src/app/core/store/interfaces/IAppState';
import { selectIdClient, selectUserType } from 'src/app/core/store/selectors';
import { BeneficiaryPlan } from '../../models/BeneficiaryPlan';
import { DocumentResult } from '../../models/DocumentResult';
import { BeneficiaryDps } from '../../models/DpsQuestions';
import { BeneficiaryType } from '../../types/BeneficiaryType';

@Component({
  selector: 'app-alteracao-cadastral',
  templateUrl: './alteracao-cadastral.component.html',
  styleUrls: ['./alteracao-cadastral.component.scss'],
})
export class AlteracaoCadastralComponent implements OnInit, OnDestroy {
  @ViewChild(MatStepper) stepper!: MatStepper;

  beneficiary?: Beneficiary;

  beneficiaryParent!: Beneficiary;

  beneficiaryParentId!: string;

  formGroup!: FormGroup;

  beneficiaryId!: string;

  mode: 'Adicionar' | 'Editar' | 'EditarPlano' = 'Adicionar';

  type!: BeneficiaryType;

  beneficiaryFormValue!: Beneficiary;

  beneficiaryDocuments!: DocumentResult[];

  selectedPlan!: BeneficiaryPlan;

  beneficiaryDps!: BeneficiaryDps;

  showStepper = true;

  idClient!: string;

  idClientSub!: Subscription;

  ShowDS = ShowDS;

  userType: UserType | null = null;

  destroyed$ = new Subject<void>();

  constructor(
    private beneficiaryService: BeneficiaryService,

    private notification: NotificationService,
    private activatedRoute: ActivatedRoute,
    private loader: LoaderService,
    private router: Router,
    private store: Store<IAppState>,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef
  ) { }

  get beneficiaryFormGroup(): FormGroup {
    return this.formGroup.get('beneficiary') as FormGroup;
  }

  get planFormGroup(): FormGroup {
    return this.formGroup.get('plan') as FormGroup;
  }

  get dpsFormGroup(): FormGroup {
    return this.formGroup.get('dps') as FormGroup;
  }

  get documentsFormGroup(): FormGroup {
    return this.formGroup.get('documents') as FormGroup;
  }

  get shouldShowDsSection(): boolean {
    if (!this.selectedPlan) {
      return false;
    }

    const shouldMatchDS = this.userType === 'PJ' ? ShowDS.PortaldoRH : ShowDS.PortalDoColaborador;

    return this.selectedPlan?.showds === shouldMatchDS;
  }

  ngOnInit(): void {
    this.buildForm();
    this.loadRouteParams();
    this.loadQueryParams();
    this.setMode();
    this.setFlow();
    this.setIdClient();
    this.setUserType();
  }

  ngOnDestroy(): void {
    this.idClientSub.unsubscribe();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onFormSaved(beneficiary: Beneficiary): void {
    this.beneficiaryFormValue = beneficiary;
    this.formGroup.get('beneficiary')?.setValue(true);

    //if (this.mode === 'Editar') {
    //  this.updateBeneficiary();
    //  return;
    //}
    this.stepper.next();
  }

  onDocumentsSaved(files: DocumentResult[]): void {
    this.beneficiaryDocuments = files;
    this.formGroup.get('documents')?.setValue(true);

    if (this.mode === 'Adicionar') {
      this.createBeneficiary();
    }
    else {
      this.updateBeneficiary();
    }
  }

  onFormCanceled(): void {
    this.goBack();
  }

  goToStep(stepIndex: number): void {
    if (this.mode === 'EditarPlano') {
      this.onFormCanceled();
    }
    this.stepper.selectedIndex = stepIndex;
  }

  goBack(): void {
    this.router.navigate(['dados-cadastrais', 'selecionar-beneficiario']);
  }

  onPlanSelected(selectedPlan: BeneficiaryPlan): void {
    this.selectedPlan = selectedPlan;
    this.selectedPlan.idClient = this.idClient;
    this.formGroup.get('plan')?.setValue(true);

    if (this.mode === 'EditarPlano') {
      this.loader.show('Atualizando plano...');
      this.beneficiaryService
        .updateBeneficiaryPlan(this.selectedPlan)
        .subscribe((result: any) => {
          this.notification.success(result.message);
          this.goBack();
        })
        .add(() => {
          this.loader.hide();
        });
      return;
    }

    this.stepper.next();
  }

  onSaveDps(beneficiaryDps: BeneficiaryDps): void {
    this.beneficiaryDps = beneficiaryDps;
    this.formGroup.get('dps')?.setValue(true);
    this.stepper.next();
  }

  getCreateBeneficiaryPayload(): CreateBeneficiaryPayload {
    let effectiveDate = '';

    const formEffectiveDate = this.beneficiaryFormValue.effectiveDate;

    if (formEffectiveDate && !formEffectiveDate?.includes('/')) {
      effectiveDate = format(parse(formEffectiveDate, 'ddMMyyyy', new Date()), 'dd/MM/yyyy');
    }

    const payload: CreateBeneficiaryPayload = {
      idClient: this.idClient,
      beneficiary: {
        idHolder: this.beneficiaryParentId,
        address: this.beneficiaryFormValue.address,
        addressComplement: this.beneficiaryFormValue.addressComplement,
        addressNumber: this.beneficiaryFormValue.addressNumber,
        birthDate: this.beneficiaryFormValue.birthDate,
        cardNumber: this.beneficiaryFormValue.cardNumber,
        cardNumberOdonto: this.beneficiaryFormValue.cardNumberOdonto,
        cellNumber: this.beneficiaryFormValue.cellNumber,
        cep: this.beneficiaryFormValue.cep,
        city: this.beneficiaryFormValue.city,
        cpf: this.beneficiaryFormValue.cpf,
        dddCell: this.beneficiaryFormValue.dddCell,
        dddHomePhone: this.beneficiaryFormValue.dddHomePhone,
        district: this.beneficiaryFormValue.district,
        email: this.beneficiaryFormValue.email,
        gender: this.beneficiaryFormValue.gender,
        homePhone: this.beneficiaryFormValue.homePhone,
        issuer: this.beneficiaryFormValue.issuer,
        kinship: this.beneficiaryFormValue.kinship,
        maritalStatus: this.beneficiaryFormValue.maritalStatus,
        motherName: this.beneficiaryFormValue.motherName,
        preferredName: this.beneficiaryFormValue.preferredName,
        name: this.beneficiaryFormValue.name,
        rg: this.beneficiaryFormValue.rg,
        weddingDate: this.beneficiaryFormValue.weddingDate,
        status: 'Ativo',
        type: this.type,
        uf: this.beneficiaryFormValue.uf,
        healthPlan: this.getHealthPlan(),
        effectiveDate,
        ruleCode: this.beneficiaryFormValue.ruleCode,
        lotacao: this.beneficiaryFormValue.lotacao,
      },
      documents: this.beneficiaryDocuments.map((d) => ({
        code: d.codigo,
        description: d.descricao,
        required: d.obrigatorio,
        url: '',
      })),
    };

    if (this.shouldShowDsSection) {
      payload.dps = {
        height: this.beneficiaryDps.height,
        weight: this.beneficiaryDps.weight,
        questions: this.beneficiaryDps.questions.map((q) => ({
          code: q.codigo,
          dataEvento: q.dataEvento,
          description: q.descricao,
          observation: q.observacao,
          value: q.valor,
        })),
      };
    }

    return payload;
  }

  separarCamelCase(texto: string) {
    // Usa uma expressão regular para encontrar transições entre maiúsculas e minúsculas
    // e insere espaços entre elas
    return texto.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  private updateBeneficiary(): void {
    this.loader.show('Atualizando informações...');
    this.beneficiaryService
      .updateBeneficiary(this.beneficiaryFormValue)
      .subscribe(() => {
        this.notification.success('Informações atualizadas com sucesso!');
        this.goBack();
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private createBeneficiary(): void {
    this.loader.show(`Criando novo ${this.type}...`);
    this.beneficiaryService
      .create(
        this.getCreateBeneficiaryPayload(),
        this.beneficiaryDocuments.map((d) => d.file)
      )
      .subscribe(() => {
        if (this.type === 'Titular') {
          this.notification.success('Titular criado com sucesso!');
          this.goBack();
          return;
        }

        this.showAddMoreOneDependentPopup();
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private resetForm(): void {
    this.showStepper = false;

    this.loader.show('Limpando formulário...');
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      this.loader.hide();
      this.showStepper = true;
    }, 200);
  }

  private showAddMoreOneDependentPopup(): void {
    this.notification
      .confirm({
        title: `Novo dependente criado com sucesso!`,
        html: `
          Inclusão sujeita à análise da administradora.<br />
          Deseja adicionar mais um dependente?
        `,
        confirmButtonText: `Sim, quero adicionar`,
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          this.resetForm();
        } else {
          this.goBack();
        }
      });
  }

  private setFlow(): void {
    this.setBeneficiaryType();

    if (this.mode === 'Editar') {
      this.loadBeneficiary();
      return;
    }
  }

  private setMode(): void {
    const hasBeneficiaryId = !!this.beneficiaryId;
    this.mode = hasBeneficiaryId ? 'Editar' : 'Adicionar';
    this.activatedRoute.url.subscribe((url) => {
      const urlPath = url[0].path;
      const isPlanEdit = urlPath.includes('plano');
      if (isPlanEdit) {
        this.mode = 'EditarPlano';
      }
    });
  }

  private setBeneficiaryType(): void {
    this.activatedRoute.url.subscribe((url) => {
      const urlPath = url[0].path;

      this.type = urlPath.includes('titular') ? 'Titular' : 'Dependente';
    });
  }

  private loadBeneficiary(): void {
    this.loader.show('Buscando dados do beneficiário...');
    this.beneficiaryService
      .findBeneficiaryById(this.beneficiaryId)
      .subscribe((beneficiary) => {
        this.beneficiary = beneficiary;
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private loadRouteParams(): void {
    this.activatedRoute.params.subscribe(({ id }) => {
      this.beneficiaryId = id;
    });
  }

  private loadQueryParams(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.t) {
        this.beneficiaryParentId = params.t;
        this.loaderBeneficiaryParent();
      } else {
        this.beneficiaryParentId = this.beneficiaryId;
      }
    });
  }

  private loaderBeneficiaryParent(): void {
    this.beneficiaryService.findBeneficiaryById(this.beneficiaryParentId).subscribe((beneficiary) => {
      this.beneficiaryParent = beneficiary;
    });
  }

  private setIdClient(): void {
    this.idClientSub = this.store.select(selectIdClient).subscribe((idClient) => {
      if (idClient) {
        this.idClient = idClient;
      }
    });
  }

  private getHealthPlan(): BeneficiaryHealthPlan {
    if (this.beneficiaryParent) {
      return {
        benefitType: this.beneficiary?.healthPlan.benefitType ?? '',
        ans: this.selectedPlan?.ansCode,
        codeEntity: this.beneficiaryParent.healthPlan.codeEntity,
        codeProvider: this.beneficiaryParent.healthPlan.codeProvider,
        accommodation: this.selectedPlan?.accommodation,
        contract: this.beneficiaryParent.healthPlan.contract,
        subContract: this.beneficiaryParent.healthPlan.subContract,
        coparticipation: true,
        coverage: this.selectedPlan?.coverage,
        entity: this.beneficiaryParent.healthPlan.entity,
        plan: this.selectedPlan?.code,
        provider: this.beneficiaryParent.healthPlan.provider,
      };
    }

    return {
      benefitType: this.beneficiary?.healthPlan.benefitType ?? '',
      ans: this.selectedPlan?.ansCode,
      accommodation: this.selectedPlan?.accommodation,
      coverage: this.selectedPlan?.coverage,
      plan: this.selectedPlan?.code,
      coparticipation: true,
      codeEntity: '',
      codeProvider: '',
      contract: '',
      subContract: '',
      entity: '',
      provider: '',
    };
  }

  private buildForm(): void {
    const isAdd = this.mode === 'Adicionar';
    const optionalSteppersValidation = isAdd ? Validators.requiredTrue : undefined;

    this.formGroup = this.formBuilder.group({
      beneficiary: [null, Validators.requiredTrue],
      plan: [null, optionalSteppersValidation],
      dps: [null, optionalSteppersValidation],
      documents: [null, optionalSteppersValidation],
    });
  }

  private setUserType(): void {
    this.store
      .select(selectUserType)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((userType) => {
        this.userType = userType;
      });
  }

}
