import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, delay, filter, map, switchMap, take, tap } from 'rxjs/operators';
import packageJsonInfo from '../../../../package.json';
import { DefaultErrorResponse } from '../models/DefaultErrorResponse';
import { SignInCredentials } from '../models/SignInCredentials';
import { AuthService } from '../services/auth';
import { ConsentFormService } from '../services/consentForm/consent-form.service';
import { CookieService } from '../services/cookie';
import { EnterpriseService } from '../services/enterprise/enterprise.service';
import { LayoutService } from '../services/layout/layout.service';
import { LoaderService } from '../services/loader';
import {
  CoreActions,
  loadEnterprise,
  setAppVersion,
  setAuthData,
  setConsentForms,
  setEnterprise,
  showCookieAcceptance,
  signInError
} from './actions';
import { IAppState } from './interfaces/IAppState';
import {
  selectConsentForms,
  selectEnterprise,
  selectHasEnterprise,
  selectIsAppVersionUpdated,
  selectIsCookieAccepted,
  selectIsLogged,
  selectUser
} from './selectors';

@Injectable()
export class CoreEffects {
  redirectIfLoggedIn$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CoreActions.RedirectIfLoggedIn),
        switchMap(() => this.store.select(selectIsLogged).pipe(take(1))),
        filter((v) => v),
        tap(() => this.router.navigate(['inicio']))
      );
    },
    {
      dispatch: false,
    }
  );

  signOut$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CoreActions.SignOut),
        tap(() => this.router.navigate(['autenticacao']))
      );
    },
    {
      dispatch: false,
    }
  );

  signInAttempt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.SignInAttempt),
      tap((action) => {
        this.loader.show('Entrando...');
      }),
      switchMap((action: { payload: SignInCredentials }) =>
        this.authService.signIn(action.payload).pipe(
          catchError((e: DefaultErrorResponse) => {
            this.loader.hide();
            // eslint-disable-next-line ngrx/no-dispatch-in-effects
            this.store.dispatch(signInError({ payload: e }));
            throw e;
          })
        )
      ),
      tap(() => this.loader.hide()),
      tap(() => this.router.navigate(['inicio'])),
      map((response) => setAuthData({ payload: response }))
    );
  });

  loadConsentForms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.LoadConsentForms),
      switchMap(() => this.store.select(selectUser).pipe(take(1))),
      switchMap((response) => this.consentFormService.getConsentForm(response?.idClient)),
      map((response) => setConsentForms({ payload: response })),
      catchError((err, caught) => {
        this.loader.hide();
        return EMPTY;
      })
    );
  });

  initEnterprise$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => this.store.select(selectEnterprise).pipe(take(1))),
      tap((e) => e && this.layoutService.setEnterpriseCssColors(e)),
      switchMap((enterprise) =>
        this.store.select(selectIsAppVersionUpdated).pipe(
          take(1),
          switchMap((isUpdated) =>
            this.store.select(selectHasEnterprise).pipe(
              take(1),
              map(
                (hasEnterprise) =>
                  !hasEnterprise || !isUpdated || enterprise?.tenantNome !== this.enterpriseService.DEFAULT_SUBDOMAIN
              )
            )
          )
        )
      ),
      filter(Boolean),
      map(() => loadEnterprise())
    );
  });

  loadEnterprise$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.LoadEnterprise),
      tap(() => this.loader.show('Atualizando dados da empresa...')),
      switchMap(() => this.enterpriseService.getEnterprise()),
      tap(() => this.loader.hide()),
      tap((e) => this.layoutService.setEnterpriseCssColors(e)),
      map((enterprise) => setEnterprise({ payload: enterprise })),
      catchError((err, caught) => {
        this.loader.hide();
        return EMPTY;
      })
    );
  });

  setProjectVersion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.SetEnterprise),
      map(() => setAppVersion({ payload: packageJsonInfo.version }))
    );
  });

  showCookieAcceptance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => this.store.select(selectIsCookieAccepted)),
      filter((isCookieAccepted) => !isCookieAccepted),
      delay(500),
      map(() => showCookieAcceptance())
    );
  });

  showPolicysAcceptance$ = createEffect(
    () => {
      return this.actions$.pipe(ofType(CoreActions.ShowPolicysAcceptance));
    },
    {
      dispatch: false,
    }
  );

  acceptCookie$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CoreActions.CookieAccepted),
        switchMap(() => this.store.select(selectEnterprise).pipe(take(1))),
        switchMap((enterprise) => this.cookieService.acceptCookie(enterprise?.tenantNome)),
        tap(() => this.loader.hide())
      );
    },
    {
      dispatch: false,
    }
  );

  acceptPolicy$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CoreActions.PolicysAccepted),
        delay(1000),
        switchMap(() => this.store.select(selectConsentForms).pipe(take(1))),
        tap(() => this.loader.show('Aceitando políticas...')),
        switchMap((consents) => this.consentFormService.saveConsent(consents)),
        tap(() => this.loader.hide())
      );
    },
    {
      dispatch: false,
    }
  );

  isAcceptPolicy$ = createEffect(
    () => {
      return this.actions$.pipe(ofType(CoreActions.IsPolicysAccepted));
    },
    {
      dispatch: false,
    }
  );

  constructor(
    private store: Store<IAppState>,
    private actions$: Actions,
    private enterpriseService: EnterpriseService,
    private layoutService: LayoutService,
    private loader: LoaderService,
    private authService: AuthService,
    private router: Router,
    private cookieService: CookieService,
    private consentFormService: ConsentFormService
  ) {}
}
