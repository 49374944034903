import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/interfaces/IAppState';
import { selectTenantId, selectToken } from '../../store/selectors';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { EnterpriseService } from '../../services/enterprise/enterprise.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  token?: string;

  tenantId$ = this.store.select(selectTenantId).pipe(
    filter((x) => !!x),
    take(1),
    map((tenantId) => `${tenantId}`)
  );

  subdomain = this.enterpriseService.getSubdomain();

  constructor(private store: Store<IAppState>, private enterpriseService: EnterpriseService) {
    this.setToken();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.shouldAddTokenInformation(request)) {
      return next.handle(request);
    }

    if (this.token) {
      request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${this.token}`) });
    }

    // informa para o back-end que a requisição está sendo feita pelo portal
    request = request.clone({ headers: request.headers.set('p', '1') });

    if (!this.shouldAddTenantIdHeader(request)) {
      return next.handle(request);
    }

    return this.tenantId$.pipe(
      tap((tenantId) => (request = this.addTenantIdToHeader(request, tenantId))),
      switchMap(() => next.handle(request))
    );
  }

  private shouldAddTokenInformation(request: HttpRequest<unknown>): boolean {
    const environmentUrl = environment.url;
    return request.url.startsWith(environmentUrl);
  }

  private shouldAddTenantIdHeader(request: HttpRequest<unknown>) {
    const controller = this.extractController(request);
    return !(this.alreadyHasTenantIdHeader(request) || controller === 'enterprise');
  }

  private alreadyHasTenantIdHeader(request: HttpRequest<unknown>): boolean {
    return !!request.headers.get('tenantId');
  }

  private setToken(): void {
    this.store.select(selectToken).subscribe((token) => {
      this.token = token;
    });
  }

  private extractController(request: HttpRequest<unknown>): string {
    return new URL(request.url).pathname.split('/')?.[3];
  }

  private addTenantIdToHeader(request: HttpRequest<unknown>, tenantId: string): HttpRequest<unknown> {
    return request.clone({ headers: request.headers.set('tenantid', tenantId).set('tenantNome', this.subdomain) });
  }
}
