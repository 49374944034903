import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/core/services/config';
import { EnterpriseService } from 'src/app/core/services/enterprise/enterprise.service';
import { LoaderService } from 'src/app/core/services/loader';
import { NotificationService } from 'src/app/core/services/notification';
import { UserService } from 'src/app/core/services/user';
import { IAppState } from 'src/app/core/store/interfaces/IAppState';
import { selectTenantIdWithoutBranch } from 'src/app/core/store/selectors';
import { CreateUserRequest } from '../../models/CreateUserRequest';
import { FirstAccessResponse } from '../../models/FirstAccessResponse';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent implements OnInit {
  firstAccessResponse!: FirstAccessResponse;
  formGroup!: FormGroup;

  tenantId!: string;

  tenantIdSub!: Subscription;

  enableEmail = this.config.getConfiguration('ENABLE_EMAIL_CREATE_ACCOUNT');

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private loader: LoaderService,
    private notification: NotificationService,
    private store: Store<IAppState>,
    private config: ConfigService,
    private enterpriseService: EnterpriseService
  ) {
    this.loadFirstAccessResponse();
  }

  ngOnInit(): void {
    this.buildForm();
    this.setTenantId();
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      this.notification.info('Preencha os campos corretamente');
      return;
    }

    this.loader.show('Criando novo usuário...');
    this.userService
      .createUser(this.getCreateUserRequest(), this.enterpriseService.getSubdomain())
      .subscribe(() => {
        if (this.firstAccessResponse.cgc.length === 11 && this.tenantId !== '17') {
          this.notification.success(
            'Foi enviado um e-mail para seu endereço. Confirme-o e entre com suas credenciais.'
          );
        } else {
          this.notification.success('Acesso criado com sucesso!');
        }
        this.router.navigate(['autenticacao']);
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private loadFirstAccessResponse(): void {
    const currentNavigation = this.router.getCurrentNavigation();
    const navigationState = currentNavigation?.extras.state;

    this.firstAccessResponse = navigationState?.firstAccessResponse as FirstAccessResponse;

    if (!this.firstAccessResponse) {
      this.router.navigate(['autenticacao']);
    }
  }

  private setTenantId(): void {
    this.tenantIdSub = this.store.select(selectTenantIdWithoutBranch).subscribe((tenantId) => {
      if (!tenantId) {
        return;
      }

      this.tenantId = tenantId;
    });
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      name: { value: this.firstAccessResponse.name, disabled: true },
      email: {
        value: this.firstAccessResponse.email.trim(),
        disabled: !this.enableEmail,
      },
      password: null,
      passwordConfirmation: null,
    });

    this.formGroup.controls.passwordConfirmation.setValidators(this.passwordConfirmationValidator);
  }

  private getCreateUserRequest(): CreateUserRequest {
    const formData = this.formGroup.getRawValue();

    return new CreateUserRequest({
      email: formData.email,
      password: formData.password,
      passwordConfirmation: formData.passwordConfirmation,
      cgc: this.firstAccessResponse.cgc,
      branch: this.firstAccessResponse.branch,
      tenantId: this.tenantId,
      idClient: this.firstAccessResponse.idClient,
    });
  }

  private passwordConfirmationValidator = (control: AbstractControl): ValidationErrors | null => {
    const passwordConfirmation = control.value;
    const password = this.formGroup.controls.password.value;

    if (password !== passwordConfirmation) {
      return { different: true };
    }

    return null;
  };
}
