import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { parse } from 'date-fns';
import { catchError, filter, first, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { LoaderService } from 'src/app/core/services/loader';
import { NotificationService } from 'src/app/core/services/notification';
import { UserService } from 'src/app/core/services/user';
import { isCnpj, isCpf } from 'src/app/core/utils/helpers';
import { IInvoicesRequest, InvoicesRequest } from '../../models/InvoicesRequest';
import { PaymentReportService } from 'src/app/core/services/payment-report';
import { BeneficiaryService } from 'src/app/core/services/beneficiary';
import { ActivatedRoute } from '@angular/router';
import { PaymentReportContract } from 'src/app/core/models/PaymentReportContract';
import { forkJoin, from, Observable, of, Subject } from 'rxjs';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { selectEnterprise } from 'src/app/core/store/selectors';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/core/store/interfaces/IAppState';

@Component({
  selector: 'app-cpf-services',
  templateUrl: './cpf-services.component.html',
  styleUrls: ['./cpf-services.component.scss'],
})
export class CpfServicesComponent implements OnInit, OnDestroy {
  enterprise$ = this.store.select(selectEnterprise);
  formGroup!: FormGroup;

  shouldShowBirthDateControl = true;

  cgcType: 'cpf' | 'cnpj' = 'cpf';

  isnot_voceclube = true;

  destroyed$ = new Subject<void>();

  constructor(
    private router: Router,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private notification: NotificationService,
    private loader: LoaderService,
    private paymentReportService: PaymentReportService,
    private beneficiaryService: BeneficiaryService,
    private route: ActivatedRoute,
    private store: Store<IAppState>
  ) {
    forkJoin({
      enterprise: this.enterprise$.pipe(take(1))
    }).subscribe(({ enterprise }) => {
      if (!enterprise) {
        this.isnot_voceclube = true;
      }
      else {
        this.isnot_voceclube = enterprise.tenantNome !== 'voceclube';
      }
    });
  }

  get resource(): string {
    return this.formGroup.get('resource')?.value ?? '';
  }

  ngOnInit(): void {
    this.buildForm();
    this.loadParams();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onSearchClick(): void {
    if (this.formGroup.invalid) {
      this.notification.info('Por favor, preencha os campos corretamente.');
      return;
    }

    switch (this.resource) {
      case 'boleto':
        this.loadInvoices();
        break;
      case 'carteirinha':
        this.loadCardNumber();
        break;
      case 'ir':
        this.loadPaymentReportContracts();
        break;
    }
  }

  private loadInvoices(): void {
    this.loader.show('Buscando boletos...');
    this.userService
      .getInvoices(this.getInvoicesRequest())
      .subscribe((invoices) => {
        this.router.navigate(['autenticacao', 'segunda-via-boleto'], { state: { listInvoice: invoices } });
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private loadCardNumber(): void {
    this.loader.show('Buscando Carteirinha(s)');

    const form = this.formGroup.getRawValue();

    this.beneficiaryService
      .getCardNumbers(form.cgc, form.birthDate)
      .subscribe((cardNumbers) => {
        this.router.navigate(['autenticacao', 'lista-carteirinha'], { state: { cardNumbers } });
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private loadPaymentReportContracts(): void {
    this.loader.show('Buscando contratos ativos...');
    const form = this.formGroup.getRawValue();

    this.paymentReportService
      .listContracts(form.cgc, form.baseYear)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((paymentReportContracts) => {
        this.getContratoAlert(paymentReportContracts).subscribe((idClient) => {
          this.getIncomeReportByIdClientAndBaseYear(idClient, form.cgc, form.baseYear);
        });
      })
      .add(() => this.loader.hide());
  }

  private getIncomeReportByIdClientAndBaseYear(contract: PaymentReportContract, cgc: string, baseYear: string): void {
    this.loader.show('Buscando Informe de Pagamentos (IR)');
    const form = this.formGroup.getRawValue();

    this.paymentReportService
      .getIncomeReportByIdClientAndBaseYear(contract.idClient, baseYear, contract.filial)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((paymentReport) => {
        this.router.navigate(['autenticacao', 'informe-de-pagamento'], {
          state: { paymentReport, cgc, baseYear },
        });
      })
      .add(() => this.loader.hide());
  }

  private loadPaymentReportOnInit(cgc: string, baseYear: string): void {
    this.loader.show('Buscando contratos ativos...');

    this.paymentReportService
      .listContracts(cgc, baseYear)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((paymentReportContracts) => {
        this.getContratoAlert(paymentReportContracts).subscribe((contract) => {
          this.getIncomeReportByIdClientAndBaseYear(contract, cgc, baseYear);
        });
      })
      .add(() => this.loader.hide());
  }

  private getInvoicesRequest(): InvoicesRequest {
    const formValue = this.formGroup.getRawValue();
    const invoice: IInvoicesRequest = {
      cgc: formValue.cgc,
    };

    if (formValue.birthDate) {
      invoice.birthDate = parse(formValue.birthDate, 'dd/MM/yyyy', 0).toISOString();
    }

    return new InvoicesRequest(invoice);
  }

  private buildForm(): void {
    this.formGroup = this.formBuilder.group({
      resource: ['boleto', Validators.required],
      cgc: ['', Validators.required],
      birthDate: [''],
      baseYear: [null],
    });

    this.setCgcType();
    this.setValidators();
  }

  private setCgcType(): void {
    this.formGroup
      .get('cgc')
      ?.valueChanges.pipe(map(String), map(isCpf))
      .subscribe((v) => {
        this.cgcType = v ? 'cpf' : 'cnpj';
        this.configureValidators();
      });
  }

  private setValidators(): void {
    this.formGroup.get('resource')?.valueChanges.subscribe((resource) => {
      this.resetForm();
      switch (resource) {
        case 'ir':
          break;
        case 'carteirinha':
          break;
      }
    });
  }

  private configureValidators(): void {
    if (this.cgcType === 'cpf' && this.resource === 'boleto') {
      this.shouldShowBirthDateControl = true;
      this.formGroup.get('birthDate')?.setValidators(Validators.required);
      return;
    }

    if (this.cgcType === 'cpf' && this.resource === 'carteirinha') {
      this.shouldShowBirthDateControl = true;
      this.formGroup.get('birthDate')?.setValidators(Validators.required);
      return;
    }

    this.shouldShowBirthDateControl = false;
    this.formGroup.get('birthDate')?.clearValidators();
    this.formGroup.get('birthDate')?.setErrors(null);
  }

  private resetForm(): void {
    this.formGroup.patchValue({
      cgc: null,
      baseYear: null,
      birthDate: null,
    });
  }

  private getContratoAlert(paymentReportContracts: PaymentReportContract[]): Observable<PaymentReportContract> {
    if (paymentReportContracts.length === 1) {
      return of(paymentReportContracts[0]);
    }

    return from(
      Swal.fire({
        confirmButtonColor: 'var(--primaryColor)',
        confirmButtonText: 'SELECIONAR',
        title: `Foi localizado mais de um contrato`,
        text: 'Por favor, selecione o contrato desejado',
        input: 'select',
        inputOptions: paymentReportContracts.reduce<any>((acc, i) => {
          acc[i.idClient] = `${i.operadora} - ${i.entidade} - ${i.contrato}`;
          return acc;
        }, {}),
      })
    ).pipe(
      map((response) => response.value),
      map((idClient) => paymentReportContracts.find((p) => p.idClient === idClient)!)
    );
  }

  private loadParams(): void {
    this.route.queryParams.subscribe(({ cpf, baseYear }) => {
      if (cpf && baseYear) {
        this.loadPaymentReportOnInit(cpf, baseYear);
      }
    });
  }
}
