import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InvoiceTableComponent } from 'src/app/core/components/invoice-table';
import { PaymentReportTableComponent } from 'src/app/core/components/payment-report-table/payment-report-table.component';
import { AuthComponent } from './components/auth';
import { CpfServicesComponent } from './components/cpf-services';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { EmailValidationComponent } from './components/email-validation';
import { FirstAccessComponent } from './components/first-access';
import { ListCardNumbersComponent } from './components/list-card-numbers';
import { LoginComponent } from './components/login';
import { LoginRedirectComponent } from './components/login-redirect';
import { LogoutComponent } from './components/logout';
import { NewPasswordComponent } from './components/new-password';
import { RecoveryPassComponent } from './components/recovery-pass';
import { RecoveryPassTokenComponent } from './components/recovery-pass-token';

const routes: Routes = [
  {
    path: 'autenticacao',
    component: AuthComponent,
    children: [
      { path: '', component: LoginComponent },
      { path: 'primeiro-acesso', component: FirstAccessComponent },
      { path: 'recuperar-senha', component: RecoveryPassComponent },
      { path: 'nova-senha', component: NewPasswordComponent },
      { path: 'criar-conta', component: CreateAccountComponent },
      { path: 'servicos-cpf', component: CpfServicesComponent },
      { path: 'segunda-via-boleto', component: InvoiceTableComponent },
      { path: 'informe-de-pagamento', component: PaymentReportTableComponent },
      { path: 'lista-carteirinha', component: ListCardNumbersComponent },
    ],
  },
  { path: 'sair', component: LogoutComponent },
  { path: 'validar-token-recuperacao', component: RecoveryPassTokenComponent },
  { path: 'confirmar-email', component: EmailValidationComponent },
  { path: 'auto-login', component: LoginRedirectComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
