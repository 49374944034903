import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isAfter, isBefore, isToday, isValid, parse } from 'date-fns';
import { isEmail } from './helpers';

export class CustomValidators {
  static email(control: AbstractControl): ValidationErrors | null {
    const value = control?.value;

    if (isEmail(value)) {
      return null;
    }

    return { invalidEmail: true };
  }

  static date(format: string): ValidatorFn {
    return (control): ValidationErrors | null => {
      const date = parse(control?.value, format, new Date());

      if (isValid(date)) {
        return null;
      }

      return { invalidDate: true };
    };
  }

  static dateBeforeToday(dateFormat: string): ValidatorFn {
    return (control): ValidationErrors | null => {
      const value = control?.value;

      if (!value) {
        return null;
      }

      const date = parse(control.value, dateFormat, new Date());

      if (!isValid(date)) {
        return null;
      }

      if (!isBefore(new Date(), date)) {
        return null;
      }

      return { dateBeforeToday: true };
    };
  }

  static dateAfterToday(dateFormat: string): ValidatorFn {
    return (control): ValidationErrors | null => {
      const value = control?.value;

      if (!value) {
        return null;
      }

      const date = parse(control.value, dateFormat, new Date());

      if (!isValid(date)) {
        return null;
      }

      if (!isAfter(new Date(), date)) {
        return null;
      }

      return { dateBeforeToday: true };
    };
  }

  static dateTodayOrAfter(dateFormat: string): ValidatorFn {
    return (control): ValidationErrors | null => {
      const value = control?.value;

      if (!value) {
        return null;
      }

      const date = parse(control.value, dateFormat, new Date());

      if (!isValid(date)) {
        return null;
      }

      if (isToday(date)) {
        return null;
      }

      if (!isAfter(new Date(), date)) {
        return null;
      }

      return { dateBeforeToday: true };
    };
  }

  static dateBetween(dateFormat: string) {
    return (control: FormControl): ValidationErrors | null => {
      const value = control?.value;

      if (!value) {
        return null;
      }

      const date = parse(control.value, dateFormat, new Date());

      if (!isValid(date)) {
        return null;
      }

      if (!isBefore(new Date(), date)) {
        return null;
      }

      return { dateBeforeToday: true };
    };
  }

  static isValidCpf() {
    return (control: FormControl): ValidationErrors | null => {
      const cpf = control.value;
      if (cpf) {
        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;
        if (cpf.length < 11) {
          return null;
        }

        for (i = 0; i < cpf.length - 1; i++) {
          if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpf.substring(0, 9);
          digits = cpf.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            return { cpfNotValid: true };
          }
          numbers = cpf.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            return { cpfNotValid: true };
          }
          return null;
        } else {
          return { cpfNotValid: true };
        }
      }
      return null;
    };
  }
}
