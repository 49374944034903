import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMaskModule } from 'ngx-mask';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './components/auth';
import { LoginComponent } from './components/login';
import { CpfServicesComponent } from './components/cpf-services';
import { IncomeInformationComponent } from './components/income-information';
import { FirstAccessComponent } from './components/first-access';
import { RecoveryPassComponent } from './components/recovery-pass';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { RecoveryPassTokenComponent } from './components/recovery-pass-token';
import { NewPasswordComponent } from './components/new-password';
import { EmailValidationComponent } from './components/email-validation';
import { ComponentsModule } from 'src/app/core/components/components.module';
import { LoginRedirectComponent } from './components/login-redirect';
import { ListCardNumbersComponent } from './components/list-card-numbers';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    CpfServicesComponent,
    IncomeInformationComponent,
    FirstAccessComponent,
    RecoveryPassComponent,
    CreateAccountComponent,
    RecoveryPassTokenComponent,
    NewPasswordComponent,
    EmailValidationComponent,
    LoginRedirectComponent,
    ListCardNumbersComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatTableModule,
    MatCardModule,

    PipesModule,
    ComponentsModule,

    NgxMaskModule.forRoot(),
  ],
})
export class AuthModule {}
