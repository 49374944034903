<mat-card class="mt-4">
  <h4>Segunda via de boleto</h4>
  <p class="text-muted">Selecione um boleto</p>

  <table mat-table [dataSource]="dataSource" class="w-100">
    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef>Plano</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice.product }}</td>
    </ng-container>
    <ng-container matColumnDef="yearMonth">
      <th mat-header-cell *matHeaderCellDef>Ano/Mês</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice.yearMonth | mask : '0000/00' }}</td>
    </ng-container>

    <ng-container matColumnDef="dueDate">
      <th mat-header-cell *matHeaderCellDef>Data de Vencimento</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice.dueDate | protheusDate }}</td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Valor</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice.amount | currency : 'BRL' }}</td>
    </ng-container>

    <ng-container matColumnDef="leftover">
      <th mat-header-cell *matHeaderCellDef>Saldo</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice.leftover | currency : 'BRL' }}</td>
    </ng-container>

    <ng-container matColumnDef="interestAmount">
      <th mat-header-cell *matHeaderCellDef>Montante de juros</th>
      <td mat-cell *matCellDef="let invoice">{{ invoice.interestAmount | currency : 'BRL' }}</td>
    </ng-container>

    <ng-container matColumnDef="dischargeDate">
      <th mat-header-cell *matHeaderCellDef>Data da Baixa</th>
      <td mat-cell *matCellDef="let invoice">
        <ng-container *ngIf="invoice.dischargeDate">{{ invoice.dischargeDate | protheusDate }}</ng-container>
        <ng-container *ngIf="!invoice.dischargeDate">
          <span class="p-1 px-3 badge bg-danger">Em aberto</span>
        </ng-container>
        <ng-container *ngIf="invoice.dischargeDate === 'Negociado'">
          <span class="p-1 px-3 badge bg-warning">Negociado</span>
        </ng-container>
        <ng-container *ngIf="invoice.dischargeDate === 'Aberto Tit.Neg.'">
          <span class="p-1 px-3 badge bg-info">Aberto Tit.Neg.</span>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="observation">
      <th mat-header-cell *matHeaderCellDef>Observação</th>
      <td mat-cell *matCellDef="let invoice">
        <ng-container *ngIf="invoice.dischargeDate === 'Negociado' || invoice.dischargeDate === 'Aberto Tit.Neg.'">
          <span class="p-1 px-3 badge bg-primary">TIT NEGOCIADO</span>
        </ng-container>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let invoice; columns: displayedColumns"
      [class.c-pointer]="!invoice.dischargeDate"
      (click)="onInvoiceSelected(invoice)"
    ></tr>
  </table>
</mat-card>
