import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import configs from 'src/app/config';
import { Config } from 'src/app/config/config.interface';
import { UserType } from '../../models/UserType';
import { selectUserType } from '../../store/selectors';
import { EnterpriseService } from '../enterprise/enterprise.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  config!: Config;

  userType: UserType | null = null;

  private enterpriseService = inject(EnterpriseService);
  private store = inject(Store);

  constructor() {
    this.store.select(selectUserType).subscribe((userType) => {
      this.userType = userType;
      this.config = this.getConfig();
    });

    this.config = this.getConfig();
  }

  hasMenu(key: string): boolean {
    return !!this.config.menus?.includes(key);
  }

  getLabel(key: string): string {
    return this.config.labels?.[key] ?? key;
  }

  getConfiguration(key: string): any {
    return this.config.configurations?.[key];
  }

  private getConfig(): Config {
    const defaultConfig = configs.default;
    let config = configs[this.enterpriseService.getSubdomain()] ?? {};

    if (!config.menus) {
      config.menus = defaultConfig.menus;
    }

    config.labels = {
      ...defaultConfig.labels,
      ...config.labels,
    };

    config.configurations = {
      ...defaultConfig.configurations,
      ...config.configurations,
    };

    config = this.getPJConfigurations(defaultConfig, config);

    return config;
  }

  private getPJConfigurations(defaultConfig: Config, config: Config): Config {
    if (this.userType !== 'PJ') {
      return config;
    }

    if (config.configurations?.PJ?.menus) {
      config.menus = config.configurations.PJ.menus;
    }

    if (config.configurations?.PJ?.labels) {
      config.labels = {
        ...config.labels,
        ...config.configurations.PJ.labels,
      };
    }

    config.configurations = {
      ...config.configurations,
      ...defaultConfig.configurations?.PJ.configurations,
    };

    if (config.configurations?.PJ?.configurations) {
      config.configurations = {
        ...defaultConfig.configurations,
        ...config.configurations,
        ...config.configurations.PJ.configurations,
      };
    }
    return config;
  }
}
