import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatStepper } from '@angular/material/stepper';
import { format } from 'date-fns';
import { Beneficiary } from 'src/app/core/models/Beneficiary';
import { BeneficiaryBlockReason } from 'src/app/core/models/BeneficiaryBlockReason';
import { BlockBeneficiaryPayload } from 'src/app/core/models/BlockBeneficiaryPayload';
import { BeneficiaryService } from 'src/app/core/services/beneficiary';
import { LoaderService } from 'src/app/core/services/loader';
import { NotificationService } from 'src/app/core/services/notification';
import { IAppState } from 'src/app/core/store/interfaces/IAppState';
import { selectUser } from 'src/app/core/store/selectors';
import { CustomValidators } from 'src/app/core/utils/custom-validators';
import { onlyNumbers } from 'src/app/core/utils/helpers';
import { BeneficiaryType } from '../../types/BeneficiaryType';
import { DocumentResult } from '../../models/DocumentResult';

@Component({
  selector: 'app-beneficiary-block',
  templateUrl: './beneficiary-block.component.html',
  styleUrls: ['./beneficiary-block.component.scss'],
})
export class BeneficiaryBlockComponent implements OnInit {
  @ViewChild(MatStepper) stepper!: MatStepper;
  
  @Output() saved = new EventEmitter<Beneficiary>();

  /**
   * Id do beneficiário encontrado
   * na URL
   */
  beneficiaryId!: string;
  beneficiary: Beneficiary | null = null;
  idClient?: string;
  mode: 'Bloquear' | 'Desbloquear' = 'Bloquear';

  type!: BeneficiaryType;
  beneficiaryDocuments!: DocumentResult[];

  formGroup!: FormGroup;
  showStepper = true;
  blockReasons!: BeneficiaryBlockReason;
  loggedUser$ = this.store.select(selectUser);
  cantSave = false;
  beneficiaryBlock!: BlockBeneficiaryPayload;
  canVisualizeException = true;
  constructor(
    private activatedRoute: ActivatedRoute,
    private loader: LoaderService,
    private router: Router,
    private store: Store<IAppState>,
    private formBuilder: FormBuilder,
    private beneficiaryService: BeneficiaryService,
    private notification: NotificationService
  ) {}

  get beneficiaryFormGroup(): FormGroup {
    return this.formGroup.get('beneficiary') as FormGroup;
  }

  get documentsFormGroup(): FormGroup {
    return this.formGroup.get('documents') as FormGroup;
  }

  ngOnInit(): void {
    this.setMode();
    this.loadBlockReasons();
    this.loadRouteParams();
    this.buildForm();
    this.loadQueryParams();
    this.setFlow();

    this.loggedUser$.subscribe((res) => {
      this.formGroup.patchValue({
        operator: res?.cgc,
        operatorName: res?.nome,
      });
    });
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      this.notification.info('Preencha os campos corretamente');
      return;
    }
    
    if (this.beneficiary) {
      this.saved.emit(this.beneficiary);
    }
    //this.loader.show('Bloqueando Beneficiario');
    //this.beneficiaryService
    //  .blockBeneficiary(this.getBlockBeneficiaryPayLoad())
    //  .subscribe(() => {
    //    this.notification.success('Benenficiario bloqueado com sucesso!');
    //    this.disableFormAndSubmit();
    //  })
    //  .add(() => {
    //    this.loader.hide();
    //  });
    this.stepper.next();
  }

  getBlockBeneficiaryPayLoad(): BlockBeneficiaryPayload {
    const formValue = this.formGroup.getRawValue();
    return {
      ...this.beneficiary,
      operator: formValue.operator,
      idClient: this.idClient,
      idBeneficiary: this.beneficiaryId,
      name: formValue.name,
      cardNumber: formValue.cardNumber,
      type: formValue.type,
      blockingDate: this.formatDate(formValue.blockingDate, true, ''),
      reasonCode: formValue.reasonCode,
      lockType: formValue.lockType,
      observation: formValue.observation,
      oldCardNumber: formValue.oldCardNumber,
      requestedBlockingDate: this.formatDate(formValue.requestedBlockingDate, true, ''),
      releaseBlockingDate: this.formatDate(formValue.releaseBlockingDate, true, ''),
      blockingTime: formValue.blockingTime,
      excepicion: formValue.excepicion,
      blockFat: formValue.blockFat,
    };
  }

  goToStep(stepIndex: number): void {
    this.stepper.selectedIndex = stepIndex;
  }

  onFormCanceled(): void {
    this.goBack();
  }

  goBack(): void {
    this.router.navigate(['dados-cadastrais', 'selecionar-beneficiario']);
  }

  onDocumentsSaved(files: DocumentResult[]): void {
    this.beneficiaryDocuments = files;
    this.formGroup.get('documents')?.setValue(true);

    this.blockBeneficiary();
  }

  private blockBeneficiary() {
    this.loader.show('Bloqueando Beneficiario');
    this.beneficiaryService
      .blockBeneficiary(this.getBlockBeneficiaryPayLoad())
      .subscribe(() => {
        this.notification.success('Benenficiario bloqueado com sucesso!');
        this.goBack();
      })
      .add(() => {
        this.loader.hide();
      });

      this.goBack();
  }

  private loadBlockReasons(): void {
    this.loader.show('Buscando Motivos disponíveis...');
    const tipo = this.mode === 'Bloquear' ? 0 : 1;
    this.beneficiaryService
      .loadBlockReaons(tipo)
      .subscribe((blockReasons) => {
        this.blockReasons = blockReasons;
      })
      .add(() => this.loader.hide());
  }

  private loadBeneficiaryBlockInfos() {
    this.loader.show('Verificando se beneficiario já está bloqueado...');
    if (this.beneficiary) {
      this.beneficiaryService
        .getBeneficiaryBlock(this.beneficiary?.idBeneficiary)
        .subscribe((res: BlockBeneficiaryPayload) => {
          if (res.blockingDate) {
            this.beneficiaryBlock = res;
            this.fillFormBeneficiaryBlock(this.beneficiaryBlock);
          }
        })
        .add(() => this.loader.hide());
    }
  }

  private buildForm() {
    this.formGroup = this.formBuilder.nonNullable.group({
      operator: [''],
      operatorName: [''],
      name: ['', Validators.required],
      cardNumber: ['', Validators.required],
      blockFat: ['1', Validators.required],
      type: [this.mode === 'Bloquear' ? '0' : '1', Validators.required],
      blockingDate: ['', [Validators.required, CustomValidators.dateTodayOrAfter('ddMMyyyy')]],
      reasonCode: ['', Validators.required],
      lockType: ['', Validators.required],
      observation: [''],
      oldCardNumber: [''],
      blockInvoice: [''],
      requestedBlockingDate: ['', [CustomValidators.dateTodayOrAfter('ddMMyyyy')]],
      releaseBlockingDate: [
        this.obterDataAtual(),
        [Validators.required, CustomValidators.dateTodayOrAfter('ddMMyyyy')],
      ],
      blockingTime: [this.obterHoraAtual()],
      excepicion: ['', Validators.required],
    });

    this.formGroup.controls.name.disable();
    this.formGroup.controls.cardNumber.disable();
    this.formGroup.controls.type.disable();
    this.formGroup.controls.releaseBlockingDate.disable();
    this.formGroup.controls.blockingTime.disable();
    this.formGroup.controls.operatorName.disable();

    if (this.type === 'Dependente') {
      this.formGroup.controls.lockType.disable();
      this.formGroup.controls.lockType.setValue('U');
      this.formGroup.controls.excepicion.disable();
      this.formGroup.controls.excepicion.setValue('N');
      this.canVisualizeException = false;
    }
  }

  private setMode() {
    this.activatedRoute.url.subscribe((urlSegments) => {
      const path = urlSegments[0].path;
      this.mode = path.startsWith('bloquear') ? 'Bloquear' : 'Desbloquear';
    });
  }

  private fillForm(beneficiary: Beneficiary): void {
    this.formGroup.patchValue({
      name: beneficiary.name,
      cardNumber: beneficiary.cardNumber,
    });
  }

  private fillFormBeneficiaryBlock(beneficiary: BlockBeneficiaryPayload): void {
    this.formGroup.patchValue({
      blockingDate: this.formatDateInverse(beneficiary.blockingDate),
      releaseBlockingDate: this.formatDateInverse(beneficiary.releaseBlockingDate as string),
      blockingTime: beneficiary.blockingTime,
      type: beneficiary.type,
      lockType: beneficiary.lockType,
      reasonCode: beneficiary.reasonCode,
      operatorName: beneficiary.operator,
      observation: beneficiary.observation,
      excepicion: beneficiary.excepicion,
      blockFat: beneficiary.blockFat,
    });

    this.disableFormAndSubmit();
  }

  /**
   * Interpreta os route params
   */
  private loadRouteParams(): void {
    this.activatedRoute.params.subscribe(({ id, beneficiaryType }) => {
      this.beneficiaryId = id;
      this.type = beneficiaryType === 'Titular' ? 'Titular' : 'Dependente';
    });
  }

  /**
   * Interpreta os query params
   */
  private loadQueryParams(): void {
    this.activatedRoute.queryParams.subscribe(({ idClient }) => {
      this.idClient = idClient;
    });
  }

  private setFlow(): void {
    this.loadBeneficiary();
  }

  private loadBeneficiary(): void {
    this.loader.show('Buscando dados do beneficiário...');
    this.beneficiaryService
      .findBeneficiaryById(this.beneficiaryId)
      .subscribe((res) => {
        this.beneficiary = res;
        this.loadBeneficiaryBlockInfos();
        this.fillForm(res);
      })
      .add(() => {
        this.loader.hide();
      });
  }

  private obterHoraAtual() {
    return format(new Date(), 'HH:mm:ss');
  }

  private obterDataAtual() {
    return format(new Date(), 'dd/MM/yyyy');
  }

  private formatDate(dateString: string, reverse = false, separator = '-'): string {
    dateString = onlyNumbers(dateString);
    const date = dateString?.slice(0, 2);
    const month = dateString?.slice(2, 4);
    const year = dateString?.slice(4, 8);

    let result = [date, month, year].filter(Boolean);

    if (reverse) {
      result = result.reverse();
    }

    return result.join(separator);
  }

  private formatDateInverse(dateString: string): string {
    dateString = onlyNumbers(dateString);
    const year = dateString?.slice(0, 4);
    const month = dateString?.slice(4, 6);
    const date = dateString?.slice(6, 8);

    const result = [date, month, year].filter(Boolean);

    return result.join('');
  }

  private disableFormAndSubmit() {
    if (this.formGroup.disabled) {
      this.formGroup.enable();
    }
    else {
      this.formGroup.disable();
    }
    this.cantSave = true;
  }
}
